<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Categoria de Carta  - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                
                <b-col md="3">
                  <div class="w-100 text-center">
                     <img class="img-fluid" :src="previewImage">
                  </div>
                </b-col>

                <b-col md="9">
                  <b-row>
                      <b-col md="12">
                        <b-form-group label="Nombre:">
                          <b-form-input type="text" disabled v-model="category_letter.name" placeholder="Ingrese un nombre"></b-form-input>
                        </b-form-group>
                      </b-col>
              
                      <b-col md="12">
                        <b-form-group label="Descripción :">
                          <b-form-input disabled type="text" placeholder="Ingrese una descripción" v-model="category_letter.description"></b-form-input>
                        </b-form-group>
                      </b-col>
                      
                      <b-col md="3">
                        <b-form-group label="Nombre de Impresora:">
                          <b-form-input disabled v-model="category_letter.printer_name"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="IP de Impresora:">
                          <b-form-input disabled v-model="category_letter.printer_ip"></b-form-input>
                        </b-form-group>
                      </b-col>

                      <b-col md="3">
                        <b-form-group label="IP de Impresora Sec.:">
                          <b-form-input disabled v-model="category_letter.printer_id_secondary"></b-form-input>
                        </b-form-group>
                      </b-col>
                      
                      <b-col md="3">
                        <b-form-group label="Estado :">
                          <b-form-select disabled v-model="category_letter.state" :options="state"></b-form-select>
                        </b-form-group>
                      </b-col>

                      <b-col md="5"></b-col>
                      <b-col md="2">
                        <b-link class="btn form-control btn-primary" :to="{ path: '/categoria-carta/listar' }" append>REGRESAR</b-link>
                      </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

     <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'
export default {
  name: "CategoryEdit",
  props: ["id_category_letter"],
  components:{
      Keypress: () => import('vue-keypress'),
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'CategoryLetter',
      role:5,
      category_letter: {
        name: "",
        description: "",
        photo: "",
        state: 1,
        printer_ip: "",
        printer_id_secondary: "",
        printer_name: "",
      },
      state:[
        {value:1,text:'Activo'},
        {value:0,text:'Inactivo'},
      ],
      previewImage: 'https://loisjeans.id/skin/frontend/base/default/images/catalog/product/placeholder/image.jpg',
      photo: null,
      errors: {
        name: false,
        photo:false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewCategory();
  },
  methods: {
    ViewCategory,
    EditCategory,
    Validate,
    PreviewImage,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};
function PreviewImage(event) {
  var input = event.target;
  if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {
          this.previewImage = e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
  }
  this.category_letter.photo = input.files[0];
}
//ver usuario
function ViewCategory() {
  let id_category_letter = je.decrypt(this.id_category_letter);
  let me = this;
  let url = this.url_base + "category-letter/view/" + id_category_letter;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.category_letter.id_category_letter = response.data.result.id_category_letter;
        me.category_letter.name = response.data.result.name;
        me.category_letter.photo = '';
        me.category_letter.description = response.data.result.description;
        me.category_letter.state = response.data.result.state;
        me.category_letter.printer_ip = response.data.result.printer_ip;
        me.category_letter.printer_id_secondary = response.data.result.printer_id_secondary;
        me.category_letter.printer_name = response.data.result.printer_name;
        me.previewImage = me.url_base + response.data.result.photo;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

//editar usuario
function EditCategory(_this) {
  

  let me = _this;
  let url = me.url_base + "category-product/edit";
  let data = new FormData();
  data.append("id_category_letter", me.category_letter.id_category_letter);
  data.append("name", me.category_letter.name);
  data.append("description", me.category_letter.description);
  data.append("photo", me.category_letter.photo);
  data.append("state", me.category_letter.state);
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 3},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.photo = null;
        Swal.fire({ icon: 'success', text:response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text:response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//validacion de formulario
function Validate() {
  this.errors.name = this.category_letter.name.length == 0 ? true : false;
  this.errors.state = this.category_letter.state.length == 0 ? true : false;
  
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.state) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  let me = this;

   if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar la categoria ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditCategory(me);
      }
    });

  }
}
</script>
